import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { Box, Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';

import './css/contentful-pages.css';
import ArticleCard from '../components/blog-components/article-card';
import { LanguageContext } from '../providers/LanguageProvider';
import Categories from '../components/blog-components/categories';
import colors from '../themes/main-colors';
import { useTranslation } from '../hooks/useTranslation';
import { BlogArticleFragment } from '../__generated__/gatsby-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: 5,
      objectFit: 'cover',
    },
    container: {
      maxWidth: theme.spacing(154),
    },
    title: {
      fontSize: theme.spacing(3),
      color: colors.noirBlur,
      textTransform: 'uppercase',
      fontFamily: 'Forma-DJR-Display'
    },
    articleCardWrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      overflow: 'hidden',
      '& > *:nth-child(3n)': {
        marginRight: -25,
      },
    },
    itemWrapper: {
      paddingRight: 25,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
  }),
);

export const BlogTagTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const page = pageContext[language || 'en'] || pageContext['es'];

  const [tagData] = data.tagData.nodes;
  const articleData = data.articleData?.nodes || [];
  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>
          {t('BLOG_HOME')} | {tagData.name}
        </title>
      </Helmet>
      <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Categories />
      </Container>
      <Container>
        <div className={classes.root}>
          {articleData && (
            <Box>
              {articleData
                .filter((node: any) => node.node_locale === language)
                .slice(0, 1)
                .map((item: BlogArticleFragment) => (
                  <ArticleCard
                    key={item.id}
                    image={'bg'}
                    item={item}
                    categoryName
                    authorName
                    articleDate
                    style={{ height: 428, maxWidth: '100%' }}
                  />
                ))}
            </Box>
          )}
          <h2 className={classes.title}>
            {t('MOST_RECENT')} {tagData.name} {t('POSTS')}
          </h2>
          <Grid className={classes.articleCardWrapper}>
            {articleData
              .filter((node: any) => node.node_locale === language)
              .slice(1)
              .map((item: BlogArticleFragment) => (
                <Grid
                  item
                  sm={12}
                  md={4}
                  className={classes.itemWrapper}
                  key={item.id}
                >
                  {item.promoCard ? (
                    <ArticleCard
                      image={'bg'}
                      variant
                      item={item}
                      style={{ maxWidth: '100%' }}
                    />
                  ) : (
                    <ArticleCard
                      image={'top'}
                      categoryName
                      item={item}
                      authorName
                      articleDate
                      style={{ maxWidth: '100%' }}
                    />
                  )}
                </Grid>
              ))}
          </Grid>
        </div>
      </Container>
    </>
  );
};
export default BlogTagTemplate;

export const query = graphql`
  query tagPageQuery($slug: String!, $tagName: String!) {
    tagData: allArticleTag(
      filter: { slug: { eq: $slug, regex: "/^((?!placeholder).)*$/i" } }
    ) {
      nodes {
        ...BlogArticleTag
      }
    }
    articleData: allContentfulArticle(
      filter: {
        tags: { eq: $tagName }
        slug: { ne: null, regex: "/^((?!placeholder).)*$/i" }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...BlogArticle
      }
    }
  }
`;
